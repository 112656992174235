import { FC, useEffect, useRef, useState } from 'react'
import { SectionProgressIndicatorProps } from '@aether/models'
import { styled } from '@aether/styles'
import { useProgressIndicator } from './useProgressIndicator'
import { JournalPostHeader } from './JournalPostHeader'
import { DefaultHeader } from './DefaultHeader'

const HEADER_TRANSITION_DURATION = 150

const Root = styled('section', {
  width: '100vw',
  marginBottom: '-$headerHeightNormal',
  zIndex: '$progressHeader',
  height: '$progressHeaderHeightNormal',
  '@lg': {
    height: '$progressHeaderHeightLarge',
  },
  variants: {
    isSticky: {
      true: {
        transition: `opacity ${HEADER_TRANSITION_DURATION / 2}ms`,
        opacity: 0,
      },
      false: {
        opacity: 1,
        position: 'sticky',
        top: 0,
      },
    },
  },
})

export const SectionProgressIndicator: FC<SectionProgressIndicatorProps> = ({
  pictogram,
  title,
  pageBuilderRef,
  appearance = 'default',
  link,
  linkLabel,
  hideShopTheProducts,
}) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const [isSticky, setIsSticky] = useState(false)
  const scrollPercent = useProgressIndicator(rootRef, pageBuilderRef)

  // show indicator only when the scroll progress is above 0% and max 100%
  useEffect(() => {
    if (scrollPercent >= 100 || scrollPercent === 0) {
      setIsSticky(false)
    }
    if (scrollPercent < 100 && scrollPercent !== 0) {
      setIsSticky(true)
    }
  }, [scrollPercent])

  return (
    <Root ref={rootRef} isSticky={!isSticky} aria-hidden={true}>
      {(() => {
        switch (appearance) {
          case 'journalPost':
            return (
              <JournalPostHeader
                pictogram={pictogram}
                title={title}
                scrollPercent={scrollPercent}
                hideShopTheProducts={hideShopTheProducts}
              />
            )
          default:
            return (
              <DefaultHeader
                title={title}
                link={link}
                scrollPercent={scrollPercent}
                linkLabel={linkLabel}
                hideShopTheProducts={hideShopTheProducts}
              />
            )
        }
      })()}
    </Root>
  )
}
