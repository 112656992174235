import {
  ProductColorType,
  ShopifyProduct,
  ShopifyProductVariant,
} from '@aether/models'
import { styled } from '@aether/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Media,
  ProductPrice,
  ProductVariantPrice,
} from '@aether/components'
import { Discount } from '@aether/discounts/utils-context'

const ButtonWrap = styled('div', {
  display: 'grid',
  color: 'white',
})

const ContentWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '18px auto 1fr',
  alignItems: 'center',
  gridGap: '$16',
  $aetherFont: 'ui01',
  color: '$white',
  width: '100%',
})

const Color = styled('div', {
  height: 22,
  width: 22,
  border: '$white solid 1px;',
  borderRadius: '$rMax',
  overflow: 'hidden',
})

const Text = styled('span', {
  gridColumn: '2 / 3',
})
const AdditionalLabels = styled('span', {
  justifySelf: 'end',
  gridColumn: '3 / -1',
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '$12',
})
const SoldOutButton = styled('button', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$gray_light',
  color: '$black',
  $aetherFont: 'ui02',
  borderRadius: '$r1',
  height: '$buttonHeightL',
  padding: '0 $20',
  cursor: 'auto',
})

const PriceWrap = styled('div', {
  $aetherFont: 'ui01',
})

const ProductPriceWrapper = styled('span', {
  display: 'flex',
  flexDirection: 'row-reverse',
})

type AddToCartButtonProps = {
  color?: ProductColorType
  variant?: ShopifyProductVariant
  productData?: ShopifyProduct
  onNoVariantSelected?: () => void
  onAddToCart: () => void
  availableForSale?: boolean
  preOrderIndicator?: boolean
  discount?: Discount
}

export const AddToCartButton: FC<AddToCartButtonProps> = ({
  color,
  variant,
  productData,
  onNoVariantSelected,
  onAddToCart,
  availableForSale = true,
  preOrderIndicator = false,
  discount,
}) => {
  const { t } = useTranslation('cart')

  const handleAddToCartClick = async () => {
    if (!variant) {
      onNoVariantSelected && onNoVariantSelected()
      return
    }

    onAddToCart()
  }
  return (
    <ButtonWrap>
      {availableForSale ? (
        <Button
          as="button"
          appearance="badgeBlack"
          onClick={handleAddToCartClick}
          id="addToCartButton"
        >
          <ContentWrap>
            {color && (
              <Color>
                <Media
                  {...color.image}
                  hardcropRatio="square"
                  sizes={{ default: '22px' }}
                />
              </Color>
            )}
            {preOrderIndicator ? (
              <Text>{t('preorder')}</Text>
            ) : (
              <Text>{t('addToCart')}</Text>
            )}
            <AdditionalLabels>
              {(variant || productData) && (
                <PriceWrap>
                  {variant ? (
                    <ProductVariantPrice
                      productVariant={variant}
                      discount={discount}
                      promoPriceColor="white"
                    />
                  ) : (
                    <ProductPriceWrapper>
                      <ProductPrice
                        product={productData}
                        discount={discount}
                        promoPriceColor="white"
                      />
                    </ProductPriceWrapper>
                  )}
                </PriceWrap>
              )}
            </AdditionalLabels>
          </ContentWrap>
        </Button>
      ) : (
        <SoldOutButton aria-disabled={true}>{t('soldOut')}</SoldOutButton>
      )}
    </ButtonWrap>
  )
}
